.dropzone {
  align-items: center;
  border-radius: 4px;
  border: 2px dashed currentColor;
  display: flex;
  width: 100%;
  flex: 1 1 100%;
  flex-flow: column;
  font-size: 18px;
  justify-content: center;
  margin: 1rem 0;
  padding: 10px;
  text-align: center;
  &.error {
    color: #f36464;
  }
  .icon {
    font-size: 40px;
  }
}
